h1 {
  font-size: 48px;
  margin: 0;
}

p {
  font-size: 16px;
  margin: 2px;
}

.section_image {
  width: 100%;
}
