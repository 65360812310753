.title {
  font-size: 2rem;
  color: #3142c4;
  margin-bottom: 8px;
}

.description {
  font-size: 1.25rem;
  font-weight: 500;
  color: #72787f;
}

.card_group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 64px;
}

.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.card_description {
  font-size: 16px;
  color: #72787f;
  margin-bottom: 50px;
}

.card_date_group {
  display: flex;
  gap: 8px;
  font-weight: 600;
}
